import React from 'react';
import * as styles from './Action.module.scss';
import { ArrowRight } from 'react-bootstrap-icons';

const Action = ({ kontakt_2, kontakt_3, variant = '' }) => {
  return (
    <div className={`${styles.action} ${styles[variant]}`}>
      {!kontakt_2 && !kontakt_3 && (
        <div className={styles.left_side}>
          <h3>Got a project?</h3>
          <h4>Need a website? Reach out, and let’s make it happen together!</h4>
        </div>
      )}

      {kontakt_2 && !kontakt_3 && (
        <div className={styles.left_side}>
          <h3>Interested in collaborating?</h3>
          <h4>I’ll answer your questions and help you choose the best solution. Get in touch!</h4>
        </div>
      )}

      {kontakt_3 && (
        <div className={styles.left_side}>
          <h3>Have questions?</h3>
          <h4>I’m here to help! Contact me to learn more about my services.</h4>
        </div>
      )}

      <div className={styles.right_side}>
        <a href="/contact">
          <ArrowRight />
          <br />
          {!kontakt_2 && !kontakt_3 && (
            <span className={styles.text}>Don’t wait – let’s start working together today!</span>
          )}
          {kontakt_2 && !kontakt_3 && (
            <span className={styles.text}>Get in touch – I’d be happy to answer your questions!</span>
          )}
          {kontakt_3 && <span className={styles.text}>Contact me to learn more!</span>}
        </a>
      </div>
    </div>
  );
};

export default Action;
