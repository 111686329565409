import React from 'react';
import * as styles from './AboutMe.module.scss';
import { Container } from 'react-bootstrap';
import SectionTitle from '../SectionTitle/SectionTitle';
import { StaticImage } from 'gatsby-plugin-image';

const AboutMe = () => {
  return (
    <section id="omnie" className={styles.about_me}>
      <Container>
        <SectionTitle title="A Few Words" coloredText=" About Me" />

        <div className={styles.wrapper}>
          <div data-sal="slide-right" data-sal-delay="100" data-sal-duration="1000" className={styles.first_column}>
            <StaticImage
              src="../../images/aboutMe.webp"
              alt="Dariusz Wasilewski full-stack web developer"
              placeholder="blurred"
              width={400}
              className={styles.image}
            />
          </div>
          <div data-sal="slide-left" data-sal-delay="100" data-sal-duration="1000" className={styles.second_column}>
            <h3>Hello</h3>
            <p>
              Hello! My name is Dariusz Wasilewski, and I am passionate about <em>creating websites</em> from
              Jastrzębie-Zdrój. My fascination with web technologies began a long time ago and quickly evolved into a
              full-fledged career as a<em>web developer</em>. From my first steps in HTML and CSS to advanced
              technologies, I learned how to create modern, responsive, and interactive <em>websites</em> that meet the
              highest standards in both design and functionality. My goal is to create websites that not only look good
              but are also intuitive, fast, and SEO-optimized. I approach each project individually, tailoring solutions
              to the needs and expectations of my clients.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutMe;
