import React from 'react';
import * as styles from './MyServicesThumbs.module.scss';
import './MyServicesThumbs.css';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import CustomButton from '../CustomButton/CustomButton';

const MyServicesThumbs = () => {
  return (
    <div className={styles.thums_container}>
      <Tabs defaultActiveKey="1" variant="underline" justify className={` mb-3 ${styles.tabs_wrapper}`}>
        <Tab eventKey="1" title="HTML Websites">
          <div className={styles.tab_content}>
            <div className={styles.tab_content_left}>
              <h3>HTML Websites</h3>
              <p>
                I offer <em>custom website development</em> from scratch, creating unique sites fully tailored to your
                specific needs and designed to make you stand out from the competition. My custom websites go beyond
                standard templates – each site is crafted with a unique design and functionality in mind.
              </p>
              <p>
                If you're looking for a pixel-perfect graphic alignment, advanced single-page applications (SPA), or
                other custom solutions, you’ve come to the right place. <em>I build websites</em> that meet the highest
                standards for both aesthetics and usability.
              </p>
              <CustomButton text="Learn More" link="/custom-websites/" />
            </div>
            <div className={styles.tab_content_right}>
              <StaticImage
                src="../../images/html.png"
                alt="websites coded from scratch, HTML/CSS/JS websites"
                width={300}
              />
            </div>
          </div>
        </Tab>
        <Tab eventKey="2" title="Web Applications">
          <div className={styles.tab_content}>
            <div className={styles.tab_content_left}>
              <h3>Web Applications</h3>
              <p>
                Web applications are ideal when your requirements go beyond standard websites. Whether you need an
                interactive tool to simplify your clients' daily tasks or an online platform at the heart of your
                business – I am here to meet your needs.
              </p>
              <CustomButton text="Learn More" link="/web-applications/" />
            </div>
            <div className={styles.tab_content_right}>
              <StaticImage
                src="../../images/web-app.png"
                alt="web applications - websites with app functionality"
                width={300}
              />
            </div>
          </div>
        </Tab>
        <Tab eventKey="3" title="WordPress Websites">
          <div className={styles.tab_content}>
            <div className={styles.tab_content_left}>
              <h3>WordPress Websites</h3>
              <p>
                I offer <em>website development</em> based on <em>WordPress</em>, one of the most popular content
                management systems (CMS). If you need an easy-to-edit website that you can manage without coding skills,
                WordPress websites are an ideal solution.
              </p>
              <p>
                WordPress is a great choice for those looking for a fast launch without sacrificing flexibility and
                scalability. With WordPress websites, you’ll gain functionality that can easily adapt to your business
                needs.
              </p>
              <CustomButton text="Learn More" link="/wordpress-websites/" />
            </div>
            <div className={styles.tab_content_right}>
              <StaticImage src="../../images/cms.png" alt="WordPress websites" width={300} />
            </div>
          </div>
        </Tab>
        <Tab eventKey="4" title="E-Commerce Websites">
          <div className={styles.tab_content}>
            <div className={styles.tab_content_left}>
              <h3>E-Commerce Websites</h3>
              <p>
                I provide functional and secure <em>e-commerce websites</em> based on WooCommerce, one of the most
                popular solutions for online stores. My e-commerce websites enable easy product management and a smooth
                online shopping experience.
              </p>
              <p>
                <em>I develop e-commerce sites</em> that comply with the latest e-commerce standards, ensuring seamless
                operation and secure transactions. With my solutions, you can expand your online sales and customize the
                store to your business needs.
              </p>
              <CustomButton text="Learn More" link="/e-commerce-websites/" />
            </div>
            <div className={styles.tab_content_right}>
              <StaticImage src="../../images/ecommerce.png" alt="e-commerce websites - online stores" width={300} />
            </div>
          </div>
        </Tab>
        <Tab eventKey="5" title="LMS Websites – Modern Educational Platforms">
          <div className={styles.tab_content}>
            <div className={styles.tab_content_left}>
              <h3>LMS Websites – Modern Educational Platforms</h3>
              <p>
                I create LMS (Learning Management System) websites that make it easy to manage online courses, students,
                and educational materials. My LMS platforms are ideal for schools, training companies, and individuals
                offering online courses.
              </p>
              <p>
                My <em>LMS sites</em> offer features like user registration, course management, quizzes, and
                certifications. You can add new courses, track student progress, and manage the platform with an
                easy-to-use admin panel. These sites are SEO-optimized and integrate with payment systems, webinars, and
                social media.
              </p>
              <CustomButton text="Learn More" link="/lms-websites/" />
            </div>
            <div className={styles.tab_content_right}>
              <StaticImage src="../../images/lms.png" alt="LMS websites - platforms for online courses" width={300} />
            </div>
          </div>
        </Tab>
        <Tab eventKey="6" title="Logo Design">
          <div className={styles.tab_content}>
            <div className={styles.tab_content_left}>
              <h3>Logo Design</h3>
              <p>
                I offer professional <em>logo design</em>, which is a crucial part of building your brand identity. Each
                logo I create is unique, tailored to the brand's character, and stands out with modern design.
              </p>
              <p>
                My logo designs are versatile – ideal for both digital and print formats. You’ll receive the logo in
                color, monochrome, and multiple formats (PNG, SVG, PDF). I design logos that build recognition, attract
                attention, and convey your company’s values.
              </p>
              <CustomButton text="Learn More" link="/logo-design/" />
            </div>
            <div className={styles.tab_content_right}>
              <StaticImage src="../../images/logo-min-white.png" alt="logo design" width={300} />
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default MyServicesThumbs;
