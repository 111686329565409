import React, { useEffect, useState } from 'react';
import * as styles from './HeaderHome.module.scss';
import Menu from '../Menu/Menu';

const HeaderHome = ({ active }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window === undefined) return;
    if (window.innerWidth < 778) {
      setIsMobile(true);
    }
  }, []);

  return (
    <header className={styles.header}>
      <Menu active={active} />

      <div data-sal="fade" data-sal-delay="100" data-sal-duration="2000" className={styles.content}>
        {!isMobile && (
          <h1 className={styles.title}>
            <span className={styles.title_top}>Creating Modern</span>
            <br />
            <span className={styles.title_bottom}>Websites</span>
          </h1>
        )}
        {isMobile && (
          <h1 className={styles.title}>
            <span className={styles.title_top}>Creating</span>
            <br />
            <span className={styles.title_bottom}>Modern</span>
            <br />
            <span className={styles.title_top}>Web</span>
            <br />
            <span className={styles.title_bottom}>Sites</span>
          </h1>
        )}

        <h2 className={styles.subtitle}>
          Professional websites and online stores
          <br />
          in Jastrzębie-Zdrój, Poland, and worldwide.
          <br />
          Sites that attract and convert
        </h2>
      </div>
    </header>
  );
};

export default HeaderHome;
