// extracted by mini-css-extract-plugin
export var active = "ProcessSteps-module--active--3969d";
export var left = "ProcessSteps-module--left--34c75";
export var process_steps = "ProcessSteps-module--process_steps--5197d";
export var process_steps_container = "ProcessSteps-module--process_steps_container--e1c37";
export var right = "ProcessSteps-module--right--89521";
export var step = "ProcessSteps-module--step--ccbb7";
export var step_1 = "ProcessSteps-module--step_1--d5cd4";
export var step_2 = "ProcessSteps-module--step_2--692ac";
export var step_3 = "ProcessSteps-module--step_3--1236a";
export var step_4 = "ProcessSteps-module--step_4--7124c";
export var step_5 = "ProcessSteps-module--step_5--05dc4";
export var step_6 = "ProcessSteps-module--step_6--595b8";
export var step_brief = "ProcessSteps-module--step_brief--1fd0d";
export var step_content = "ProcessSteps-module--step_content--54ff1";
export var step_empty = "ProcessSteps-module--step_empty--e205c";
export var step_info = "ProcessSteps-module--step_info--d6c71";
export var step_kodowanie = "ProcessSteps-module--step_kodowanie--76cc1";
export var step_number = "ProcessSteps-module--step_number--77061";
export var step_number_last = "ProcessSteps-module--step_number_last--907a6";
export var step_number_left = "ProcessSteps-module--step_number_left--a548c";
export var step_projektowanie = "ProcessSteps-module--step_projektowanie--8b630";
export var step_testowanie = "ProcessSteps-module--step_testowanie--b7e4c";
export var step_wdrozenie = "ProcessSteps-module--step_wdrozenie--d21b0";