import React from 'react';
import * as styles from './SiteInfo.module.scss';
import { Card, Container } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';

const SiteInfo = () => {
  return (
    <section className={styles.site_info}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.first_column}>
            <h3>
              Why Choose My <em>Website Development</em> Services?
            </h3>
            <p>
              I offer comprehensive website development for clients in Jastrzębie-Zdrój, Poland, and worldwide. My sites
              are not only <strong>responsive</strong> and <strong>functional</strong> but also{' '}
              <strong>easy to manage</strong> and fully <strong>SEO-optimized</strong>. Every website I create is
              user-focused and designed with the latest trends and technologies to ensure the highest quality and
              effectiveness online.
            </p>
            <CustomButton variant="dark" text="Contact Me" link="/contact" />
          </div>
          <div className={styles.second_column}>
            <Card className={`mb-2 ${styles.first_card}`}>
              <Card.Body>
                <Card.Title as={'h3'}>
                  <em>Freelancer</em>, not an agency
                </Card.Title>
                <Card.Text>
                  As a freelancer, I offer a more personalized approach than typical agencies. I create
                  <em> custom websites</em> tailored to your company’s needs, ensuring complete flexibility, fast
                  implementation, and competitive prices. Each project is approached with full commitment and top
                  quality.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.second_card}>
              <Card.Body>
                <Card.Title as={'h3'}>Not Just WordPress!</Card.Title>
                <Card.Text>
                  Unlike many freelancers, I don’t limit myself to <em>WordPress</em> alone. With my programming skills,
                  I can build <em>websites</em> on various platforms, customizing them to fit the specific needs of your
                  clients and industry.
                  <span>
                    <strong>
                      <a href="#omnie"> See my skills and experience.</a>
                    </strong>
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className={styles.banner}>
          <h3 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="1000">
            <em>I build websites</em> for businesses of all sizes – from small startups to large corporations. No matter
            what kind of business you run, I am confident I can offer <em>websites</em> that meet your expectations and
            serve as the perfect solution for your business.
          </h3>
        </div>
      </Container>
    </section>
  );
};

export default SiteInfo;
