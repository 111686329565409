import React from 'react';
import * as styles from './MyServices.module.scss';
import { Container } from 'react-bootstrap';
import SectionTitle from '../SectionTitle/SectionTitle';
import MyServicesThumbs from '../MyServicesThumbs/MyServicesThumbs';
import MyServicesAccordion from '../MyServicesAccordion/MyServicesAccordion';
import CustomButton from '../CustomButton/CustomButton';
import { QuestionCircle } from 'react-bootstrap-icons';
import Action from '../Action/Action';
import { useMediaQuery } from 'react-responsive';

const MyServices = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <section className={styles.myServices}>
      <Container>
        <SectionTitle title="All the services you" coloredText=" need" variant="white" />

        {!isMobile && <MyServicesThumbs />}

        {isMobile && <MyServicesAccordion />}

        <div className={styles.info}>
          <QuestionCircle className={styles.icon} />
          <div className={styles.content}>
            <h3>HTML or CMS</h3>
            <p>
              Wondering whether to choose an HTML or CMS-based website? Want to understand the differences between these
              two options? Visit my blog for answers to these questions. Or, reach out, and I’ll help you find the best
              solution for your business.
            </p>
            <CustomButton text="Visit my blog" link={'/blog/'} />
          </div>
        </div>

        <Action />
      </Container>
    </section>
  );
};

export default MyServices;
