import React, { useEffect, useState } from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import * as styles from './ProcessSteps.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Container } from 'react-bootstrap';

const ProcessSteps = () => {
  const [activeStep, setActiveStep] = useState(-1);

  useEffect(() => {
    const handleScroll = () => {
      const stepElements = document.querySelectorAll(`.${styles.step}`);
      const windowHeight = window.innerHeight;

      stepElements.forEach((step, index) => {
        const rect = step.getBoundingClientRect();
        const elementMiddle = rect.top + rect.height / 2;

        if (elementMiddle < windowHeight * 0.75 && elementMiddle > windowHeight * 0.25) {
          setActiveStep(index);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className={styles.process_steps_container}>
      <Container>
        <SectionTitle title="My Website Design" coloredText=" Process" />
        <div className={styles.process_steps}>
          <div className={`${styles.step} ${styles.left} ${activeStep === 0 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_1} ${styles.step_brief}`}>
              <h3>Brief and Consultation</h3>
              <p>
                We start by defining your expectations, vision, and preferences for the website. I gather information
                about your company to create a website that best represents it.
              </p>
            </div>
            <div className={styles.step_number}>
              <StaticImage
                src="../../images/brief.png"
                alt="icon representing the initial client contact to gather information about the needed website"
                width={150}
              />
            </div>
          </div>
          <div className={`${styles.step} ${styles.right} ${activeStep === 1 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_2} ${styles.step_info}`}>
              <h3>Gathering and Preparing Content</h3>
              <p>
                At this stage, I gather all materials needed to create the website, such as texts, graphics, images, and
                other visual elements. If you don’t have content ready, I can assist in preparing it to ensure the
                website is cohesive and visually appealing.
              </p>
            </div>
            <div className={styles.step_number_left}>
              <StaticImage
                src="../../images/kontent.png"
                alt="icon representing gathering and preparing website content"
                width={150}
              />
            </div>
          </div>
          <div className={`${styles.step} ${styles.left} ${activeStep === 2 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_3} ${styles.step_projektowanie}`}>
              <h3>Designing</h3>
              <p>
                Based on your requirements, I create an initial graphic design for the website, reflecting your unique
                style and needs.
              </p>
            </div>
            <div className={styles.step_number}>
              <StaticImage src="../../images/projektowanie.png" alt="icon representing website design" width={150} />
            </div>
          </div>
          <div className={`${styles.step} ${styles.right} ${activeStep === 3 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_4} ${styles.step_kodowanie}`}>
              <h3>Website Development</h3>
              <p>
                After the design is approved, I proceed to code and build a functional website. The website is
                responsive, SEO-optimized, and easy to manage.
              </p>
            </div>
            <div className={styles.step_number_left}>
              <StaticImage
                src="../../images/tworzenie-strony.png"
                alt="icon representing website development"
                width={150}
              />
            </div>
          </div>
          <div className={`${styles.step} ${styles.left} ${activeStep === 4 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_5} ${styles.step_testowanie}`}>
              <h3>Testing and Optimization</h3>
              <p>
                Before launch, I test the website on various devices and browsers to ensure it functions flawlessly.
              </p>
            </div>
            <div className={styles.step_number}>
              <StaticImage src="../../images/testowanie.png" alt="icon representing website testing" width={150} />
            </div>
          </div>
          <div className={`${styles.step} ${styles.right} ${activeStep === 5 ? styles.active : ''}`}>
            <div className={styles.step_empty}></div>
            <div className={`${styles.step_content} ${styles.step_6} ${styles.step_wdrozenie}`}>
              <h3>Launch and Support</h3>
              <p>
                After final approval, the website is published. I offer technical support post-launch to ensure the
                website runs smoothly and assist with updates.
              </p>
            </div>
            <div className={styles.step_number_last}>
              <StaticImage src="../../images/koniec.png" alt="icon representing website deployment" width={150} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ProcessSteps;
