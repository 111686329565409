import React from 'react';
import * as styles from './Testiniomals.module.scss';
import { Container } from 'react-bootstrap';
import SectionTitle from '../SectionTitle/SectionTitle';
import { StarFill } from 'react-bootstrap-icons';
import Action from '../Action/Action';

const Testiniomals = () => {
  return (
    <section className={styles.testiniomals}>
      <Container>
        <div className={styles.title}>
          <SectionTitle coloredText="Not sure yet?" subtitle="See what my clients say about me" variant="white" />
        </div>

        <div className={styles.wrapper}>
          <div className={styles.testimonial} itemscope itemtype="https://schema.org/Review">
            <div
              className={styles.testimonial_stars}
              itemprop="reviewRating"
              itemscope
              itemtype="https://schema.org/Rating"
            >
              <meta itemprop="ratingValue" content="5" />
              <StarFill />
              <StarFill />
              <StarFill />
              <StarFill />
              <StarFill />
            </div>
            <div className={styles.testimonial_text}>
              <p itemprop="reviewBody">
                "I am thrilled with the collaboration! The online store was completed in no time, exactly as I
                requested. Visually, I couldn't have imagined it any better! Communication and cooperation were
                top-notch! Highly recommended!"
              </p>
              <p itemprop="author">- Katarzyna Mokrzycka</p>
            </div>
          </div>
          <div className={styles.testimonial} itemscope itemtype="https://schema.org/Review">
            <div
              className={styles.testimonial_stars}
              itemprop="reviewRating"
              itemscope
              itemtype="https://schema.org/Rating"
            >
              <meta itemprop="ratingValue" content="5" />
              <StarFill />
              <StarFill />
              <StarFill />
              <StarFill />
              <StarFill />
            </div>
            <div className={styles.testimonial_text}>
              <p itemprop="reviewBody">
                "The website development service was quick and professional. Working with Mr. Dariusz was a pleasure.
                All suggestions were implemented promptly. The website is beautiful and intuitive. HIGHLY
                RECOMMENDED!!!!"
              </p>
              <p itemprop="author">- Artur Przybyla</p>
            </div>
          </div>
          <div className={styles.testimonial} itemscope itemtype="https://schema.org/Review">
            <div
              className={styles.testimonial_stars}
              itemprop="reviewRating"
              itemscope
              itemtype="https://schema.org/Rating"
            >
              <meta itemprop="ratingValue" content="5" />
              <StarFill />
              <StarFill />
              <StarFill />
              <StarFill />
              <StarFill />
            </div>
            <div className={styles.testimonial_text}>
              <p itemprop="reviewBody">
                "Thank you so much for your work, and I highly recommend Mr. Dariusz to those about to embark on the
                journey of creating their own website. The key points that influenced my opinion were the very fair
                pricing and easy access to Mr. Dariusz. His dedication and knowledge were evident from the first emails
                and confirmed throughout the process."
              </p>
              <p itemprop="author">- Weronika Elveren</p>
            </div>
          </div>
          <div className={styles.testimonial}>
            <div className={styles.testimonial_text}>
              <p className={styles.google_link}>
                <a href="https://maps.app.goo.gl/2UKkcFvoNswxPgof9" target="_blank" rel="noopener noreferrer">
                  See all reviews on Google
                </a>
              </p>
            </div>
          </div>
        </div>
        <Action contact_2 variant={'bottom'} />
      </Container>
    </section>
  );
};

export default Testiniomals;
